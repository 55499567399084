<template>
	<div class="news-reader d-flex justify-content-center container-fluid">
		<div class="news-reader__inner d-flex flex-column w-100">
			<h3 class="news-reader__title mt-3">Audio Web</h3>
			<p class="news-reader__subtitle m-0">{{ $t('news-reader--p') }}</p>
			<div class="news-reader__subscription d-flex align-items-center ml-auto dropdown">
				<img
					:src="require('@/assets/images/icons/ic_subscription_info.svg')"
					class="news-reader__subscription-icon"
					alt=""
				/>
				<div class="news-reader__tooltip dropdown-menu">
					<span>{{ $t('audio-duration-left') }} <b class="cursor-pointer gv__tooltip--topup" @click="$router.push({ name : 'TopUp' })">{{ $t('top-up-more') }}</b></span>
          				<div class="gv__tooltip--package d-flex align-items-center mt-2">
							<div class="gv__tooltip--package-wrapper p-2">
								<div class="gv__tooltip--package-wrapper-inner d-flex align-items-start">
									<div class="gv__tooltip--package-quota d-flex flex-column">
										<p class="m-0 tooltip--package__text">Basic</p>
										<p class="m-0 tooltip--package__quota">{{ allocation?.audio_generates_quota || '0' }}<span class="tooltip--package__text"> mins</span></p>
									</div>
								</div>
							</div>
							<div class="gv__tooltip--package-wrapper p-2">
								<div class="gv__tooltip--package-wrapper-inner d-flex align-items-start">
									<div class="gv__tooltip--package-quota d-flex flex-column">
										<p class="m-0 tooltip--package__text">Multilingual</p>
										<p class="m-0 tooltip--package__quota">{{ allocation?.audio_multilingual_generates_quota || '0' }}<span class="tooltip--package__text"> mins</span><span class="tooltip--package__exp ml-3" v-if="allocation?.expired_date && allocation?.audio_multilingual_generates_quota">exp. {{ getMonthDate(allocation?.expired_date) }}</span></p>
									</div>
								</div>
							</div>
						</div>
				</div>
				<p class="news-reader__subscription-text m-0">{{ (allocation?.audio_generates_quota  + allocation?.audio_multilingual_generates_quota).toFixed(2) }} mins</p>
			</div>
			<main class="news-reader__main">
				<div class="news-reader__middle d-flex h-100 pb-3">
					<div class="news-reader__left-content d-flex flex-column">
						<NRInputWebUrl v-model="webUrl" @click:get-article="handleGetArticle" :disable-control="disableScrapUrl" :disable-input="disableInputUrl" :isScrapping="isScrapping"/>
						<NRPreviewAndEditor v-model="resultText" :avatar="avatar" :disable-generate="disableGenerate" :isGenerate="isGenerating" @click:change-avatar="$bvModal.show('select-voice-type--news-reader')" @click:generate-audio="handleGenerateAudio" @click:expand="$bvModal.show('expand-script-nr')"/>
					</div>
					<div class="news-reader__right-content d-flex flex-column">
						<NRSettings v-model="settings" :is-loading="isGettingEmbed" :disable-get-code="isFinal" @click:get-embed-code="handleGetEmbedCode" @click:remove-wm="handleRemoveWm"/>
						<NREmbeded :embed="embed" @click:save-to-project="handleSaveToProject" @click:copy-embed-code="handleCopyEmbedCode" :isSaving="isSaving"/>
					</div>
				</div>
			</main>
		</div>
		<NRSelectCharacterReader
			id="select-voice-type--news-reader"
			:selected-language="avatar?.language"
        	:selected-character="avatar?.character"
			:allocation="allocation"
			@change:voice-type="(newVoiceType) => {
				avatar = newVoiceType,
				$bvModal.hide('select-voice-type--news-reader');
            }"
		/>
		<NRExpand v-model="resultText" :avatar="avatar" :disable-generate="disableGenerate" :isGenerate="isGenerating" @click:change-avatar="$bvModal.show('select-voice-type--news-reader')"/>
		<QuotaHabisModal />
		<ServerErrorModal />
		<MultilingualQuotaModal />
		<SaveToProjectModal newsReader content v-model="contentName" @save-name="handleSaveNewsReader"/>
		<Soca v-if="isLoading || load_topup"/>
		<RemoveWM :status-balance-swc="statusSwcRemoveWm" :swc="balanceSwcForRemoveWm" @click:use-swc="handleClickUseSwc" @click:topup-swc="handleClickModalTopUpSwc"/>
		<VoiceServiceUnavailableModal id="voice-service-unavailable-news-reader" :type="avatar?.language?.name == 'Multilingual' ? 'Multilingual' : 'Monolingual'"/>

		<InputPin :package-id="$route.params.id" @forgot-pin="handleForgotPin" @success:remove-watermark="handleSuccessRemoveWM" :watermark="true"/>
		<SetupPin @setup-pin="requestPinCode"/>
		<VerificationCode @otp-success="openCreatePin"/>
		<CreatePin name="topup-create-pin-modal" type="create"/>

		<ModalTopupSwc :list-package="listPackageSwc" :choosed-index="choosedIndex" :total-price="totalPrice" :status-disable="buttonTopUp" :is-enough="is_enough" :load-swc="load_swc_price" v-model="custom_swc" @update:choosedIndex="handleChoosedIndex" @swc-focus="swcCustomFocus" @inputChange="handleInputSwc" @next="nextTopup()" @close-topup="handleCloseTopUp"/>
	</div>
</template>

<script>
import NRInputWebUrl from "@/components/news-reader/input-web-url"
import NRPreviewAndEditor from "@/components/news-reader/preview-and-editor"
import NRSettings from "@/components/news-reader/settings"
import NREmbeded from "@/components/news-reader/embeded"
import NRExpand from '@/components/news-reader/expand-script';
import NRSelectCharacterReader from '@/components/modal/SelectVoiceType';
import QuotaHabisModal from '@/components/modal/QuotaHabis';
import MultilingualQuotaModal from '@/components/modal/MultilingualQuota';
import ServerErrorModal from '@/components/modal/ServerError';
import RemoveWM from '@/components/modal/RemoveWatermark';
import SaveToProjectModal from '@/components/modal/RenameVoiceModel.vue'
import Soca from '@/components/loading/Soca'
import VoiceServiceUnavailableModal from '@/components/modal/VoiceServiceUnavailable';

import SetupPin from '@/components/payment/modals/SetupPin'
import VerificationCode from '@/components/pin/VerificationCode'
import CreatePin from '@/components/pin/CreatePin'
import InputPin from '@/components/payment/modals/InputPin'
import ModalTopupSwc from '@/components/topup-swc/ModalTopupSwc'

import { base_url_machine_learning } from '@/config/base_url';
import { getNewsScrapper, getGeneratedTextToAudioSSML } from '@/services/generative-ai-text-to-audio/generative-ai-text-to-audio.service';
import { EventBus } from '@/helper/event-bus.js';
import { AlertUtils } from "@/mixins/AlertUtils";
import { generateEmbedCode, saveToProjectNewsReader, getDetailNewsReader, getAllowWatermark, checkBalanceSwcRemoveWM } from '@/services/news-reader/news-reader.service'
import { subscriptionsStatus } from '@/services/subscriptions/subscriptions.service'
import { useVoiceModel } from '@/services/ttv/ttv.service.js'
import { checkStatusPin, requestSetPin } from '@/services/pin/pin.service'
import { checkPriceSwc, customSwcRequest, getPackageTopupSwc } from "@/services/swc_payment/swc_payment.service";
import { topupSwc } from '@/services/subscriptions/subscriptions.service'

import $ from "jquery"

export default {
	mixins: [AlertUtils],

	components: {
		NRInputWebUrl,
		NRPreviewAndEditor,
		NRSettings,
		NRSelectCharacterReader,
		NREmbeded,
		QuotaHabisModal,
		ServerErrorModal,
		SaveToProjectModal,
		MultilingualQuotaModal,
		NRExpand,
		Soca,
		SetupPin,
		VerificationCode,
		CreatePin,
		RemoveWM,
		InputPin,
		ModalTopupSwc,
		VoiceServiceUnavailableModal
	},

	// beforeRouteLeave(to, from, next) {
    //     this.removeDataFromStorage()
    //     next();
    // },

	data() {
		return {
			webUrl: null,
			generatedWebUrl: null,

			resultText: null,
			generatedResultText: null,
			shouldGenerate: true,

			embed: null,

			urlHtml: null,
			contentName: null,
			allocation: null,

			isScrapping: false,
			isGenerating: false,
			isGettingEmbed: false,
			isSaving: false,
			isLoading: false,
			isFinal: false,

			settings: {
				color: '#000',
				removeWatermark: false,
				useYourLogo: false,
				disableSpeed: false,
				canRemoveWm: false,
				audioSrc: null,
			},

			generatedSettings: null,

			avatar: {
				character: {
					gender: 'Female',
					name: 'Sovia',
					avatarSrc: base_url_machine_learning + 'static/template/mp3/asset_sample_languages/Indonesia/Female/avatar/Sovia.png',
					isMultilingual:false,
					isUseModel: false,
					sampleSrc: base_url_machine_learning + 'static/template/mp3/asset_sample_languages/Indonesia/Female/normal/1677909003.mp3',
					thumbnailAvatarSrc: base_url_machine_learning + 'static/template/mp3/asset_sample_languages/Indonesia/Female/avatar/thumbnails/Sovia.png',
				},
				language: {
					code: 'ID',
					flagSrc: '/img/ic_flag_indonesia.bfd56f3d.svg',
					id: '6401ae940eb4d9111ec260d7',
					name: 'Indonesia',
				}
			},
			generatedAvatar: null,

			isPopulatingAllDataOnStorage: false,
			statusSwcRemoveWm: false,
			balanceSwcForRemoveWm: 50,

			listPackageSwc : [],
			is_enough : false,
			load_swc_price : false,
			load_package_swc : false,
			load_topup : false,
			choosedIndex : null,
			id_package: null,
			custom_swc: null,
			activeTooltip: null,
            totalPrice : 0,
			swc_price : 0,

			colorLabel: ['dark', 'light', 'doodle', 'gradient-nova'] //light, dark, gradient-nova, doodle
		}
	},

	mounted() {
		$("div.news-reader").parent().addClass('pb-0');
		this.getAllocation()
		this.getSwcPrice()
		this.getPackageSwc()

		if(sessionStorage.getItem("news-reader::get-from-api") || this.$route.params.mode == 'create') {
			this.getDataFromStorage()
		} else {
			this.getDetail(this.$route.params.id)
		}
	},

	beforeDestroy() {
		$("div.news-reader").parent().removeClass('pb-0');
	},

	watch: {
		settings: {
			async handler(newValue) {
				if(!this.isPopulatingAllDataOnStorage){
					sessionStorage.setItem('news-reader::settings', JSON.stringify(newValue))
				}
				if(this.generatedSettings != null) {
					const generatedSettingsString = JSON.stringify(this.generatedSettings);
      				const newValueString = JSON.stringify(newValue);
					if(generatedSettingsString != newValueString) {
						this.isFinal = false
					} else {
						this.isFinal = true
					}
				}
			},
			deep: true,
		},
		generatedSettings: {
			async handler(newValue) {
				if(!this.isPopulatingAllDataOnStorage){
					sessionStorage.setItem('news-reader::generated-settings', JSON.stringify(newValue))
				}
			},
			deep: true,
		},
		resultText: {
			async handler(newValue) {
				if(!this.isPopulatingAllDataOnStorage){
					sessionStorage.setItem('news-reader::result-text', newValue)
				}
				if(this.generatedResultText != null) {
					if(this.generatedResultText != newValue) {
						this.shouldGenerate = true
					} else {
						this.shouldGenerate = false
					}
				}
			},
			deep: true,
 		},
		avatar: {
			async handler(newValue) {
				if(!this.isPopulatingAllDataOnStorage){
					sessionStorage.setItem('news-reader::avatar', JSON.stringify(newValue))
				}
				if(this.generatedAvatar != null) {
					if(this.generatedAvatar?.character?.name != newValue?.character?.name) {
						this.shouldGenerate = true
					} else {
						this.shouldGenerate = false
					}
				}
			},
			deep: true,
		},
		generatedAvatar: {
			handler(newValue) {
				if(!this.isPopulatingAllDataOnStorage){
					sessionStorage.setItem('news-reader::generated-avatar', JSON.stringify(newValue))
				}
			},
			deep: true
		},

		webUrl: {
			handler(newValue) {
				if(!this.isPopulatingAllDataOnStorage){
					sessionStorage.setItem('news-reader::web-url', newValue)
				}
			},
			deep: true
		},
		generatedWebUrl: {
			handler(newValue) {
				if(!this.isPopulatingAllDataOnStorage){
					sessionStorage.setItem('news-reader::generated-web-url', newValue)
				}
			},
			deep: true
		},
		generatedResultText: {
			handler(newValue) {
				if(!this.isPopulatingAllDataOnStorage){
					sessionStorage.setItem('news-reader::generated-result-text', newValue)
				}
			},
			deep: true
		},
		embed: {
			handler(newValue) {
				if(!this.isPopulatingAllDataOnStorage){
					sessionStorage.setItem('news-reader::embed', newValue)
				}
			},
			deep: true
		},

		custom_swc(val) {
			if(val) {
				this.choosedIndex = null
				this.totalPrice = val * this.swc_price
				if(val >= 50) {
					this.is_enough = true
				} else {
					this.is_enough = false
				}
			} else {
				if(!this.choosedIndex){ this.totalPrice = 0 }
			}
		}
	},

	computed: {
		disableGenerate() {
			return !this.generatedWebUrl || this.isScrapping || !this.resultText || !this.shouldGenerate ? true : false
		},
		disableInputUrl() {
			return this.isScrapping ||
			this.isGenerating
			? true : false
		},
		disableScrapUrl() {
			return this.isScrapping
			|| this.isGenerating
			|| (this.generatedWebUrl && this.generatedWebUrl == this.webUrl)
			? true : false
		},
		buttonTopUp() {
			return (
				this.choosedIndex == null &&
				(this.custom_swc == null || parseInt(this.custom_swc) < 50)
			)
		}
	},

	methods: {
		async handleGetArticle() {
			if(this.validateUrl()) {
				this.alertFail('URL is not valid!')
			} else {
				this.isScrapping = true
				let formData = {
					start_urls: this.webUrl
				}
				this.generatedWebUrl = this.webUrl

				await getNewsScrapper(formData)
				.then((response) => {
					if(response.status == 200) {
						this.resultText = response.data
					} else {
						this.generatedWebUrl = null
						this.$bvModal.show('server-error-modal');
					}
					this.isScrapping = false
				})
				.catch((error) => {
					console.error(error)
					this.$bvModal.show('server-error-modal');
					this.isScrapping = false
					this.generatedWebUrl = null
				})
			}
		},

		handleGenerateAudio() {
			// Check if user using Voice model
			this.generatedResultText = this.resultText
			this.generatedAvatar = {...this.avatar}
			if(this.avatar.character.isUseModel && !this.avatar.character?.isMultilingual) {
				this.handleGenerateVoiceModelAudio()
			} else {
				this.handleGenerateOrdinaryAudio()
			}
		},

		async handleGenerateOrdinaryAudio() {
			this.isGenerating = true
			const formData = {
				context: 'lessons',
				text: this.resultText,
				course_id	: '',
				params: {
					speaker: this.avatar.character.name.toLowerCase(),
					pitch: 0,
					speed: 1,
					emotion: 'normal',
					regenerate: 'False',
					lang: this.avatar.language.id,
					with_char : "False",
					news_reader : true
				}
			}

			try {
				const res = await getGeneratedTextToAudioSSML(formData);
				if (res.status === 403) {
					if(this.avatar.language.name == 'Multilingual') {
						this.$bvModal.show('multilingual-quota')
						this.generatedResultText = null
						this.generatedAvatar = null
					} else {
						this.$bvModal.show('audio-quota');
						this.generatedResultText = null
						this.generatedAvatar = null
					}
				} else if (res.status === 402) {
					this.$bvModal.show('voice-service-unavailable-news-reader')
				} else if (res.status === 500) {
					this.$bvModal.show('server-error-modal');
					this.generatedResultText = null
					this.generatedAvatar = null
				} else if (res.status === 406) {
					console.log("SENTIMENT")
					this.generatedResultText = null
					this.generatedAvatar = null
				} else {
					const { data } = res;
					this.settings.audioSrc = base_url_machine_learning + data.path
					this.shouldGenerate = false
					EventBus.$emit('triggerSidebarMethod');
				}
				this.getAllocation()
				this.isGenerating = false
			} catch (error) {
				console.log(error);
				this.$bvModal.show('server-error-modal');
				this.isGenerating = false
				this.generatedResultText = null
				this.generatedAvatar = null
			}
		},

		async handleGenerateVoiceModelAudio() {
			this.isGenerating = true
			const formData = {
				id_models: this.avatar.character?.id,
				text: this.resultText,
				lang: this.avatar.character.lang,
				course_id: null,
				params: {
					with_char: "False"
				}
			}
			try {
				const res = await useVoiceModel(formData);
				if (res.status === 403) {
					this.$bvModal.show('audio-quota');
					this.generatedResultText = null
					this.generatedAvatar = null
				} else if (res.status === 402 || res.status === 500) {
					this.$bvModal.show('server-error-modal');
					this.generatedResultText = null
					this.generatedAvatar = null
				} else if (res.status === 406) {
					this.generatedResultText = null
					this.generatedAvatar = null
				} else {
					const { data } = res;
					this.settings.audioSrc = data.path
					EventBus.$emit('triggerSidebarMethod');
				}
				this.getAllocation()
				this.isGenerating = false
			} catch (error) {
				this.$bvModal.show('server-error-modal');
				this.isGenerating = false
				this.generatedResultText = null
				this.generatedAvatar = null
			}
		},

		validateUrl() {
			const urlPattern = /^https:\/\/\w+(\.\w+)*(:\d+)?(\/\S*)?$/i;
    		return !urlPattern.test(this.webUrl);
		},

		async handleGetEmbedCode() {
			this.isGettingEmbed = true
			this.generatedSettings = { ...this.settings };
			let colorLabel = this.settings.color == '#000' ? 'dark' : this.settings.color == '#FFFFFF' ? 'light' :  this.settings.color == 'Doodle' ? 'doodle' : 'gradient-nova'

			let formData = {
				style: colorLabel,
				watermark: !this.settings.removeWatermark,
				speed: !this.settings.disableSpeed,
				voice_file: this.settings.audioSrc
			}

			let id = this.$route.params.id

			await generateEmbedCode(formData, id)
			.then((response) => {
				if(response.status) {
					this.embed = response.data.embed_code
					this.urlHtml = response.data.html_url
					this.isFinal = true
				} else {
					this.generatedSettings = null
					this.alertFail('Failed to get Embed Code')
				}
				this.isGettingEmbed = false
			})
			.catch((error) => {
				this.alertFail('Failed to get Embed Code')
				this.isGettingEmbed = false
				this.generatedSettings = null
				console.error(error)
			})
		},

		handleCopyEmbedCode() {
			let vm = this
			vm.$copyText(vm.embed).then(function () {
                vm.alertSuccess('Embed successfully copied')
            }, function () {
                vm.alertFail('Embed failed to copy')
            })
		},

		handleSaveToProject() {
			this.$bvModal.show('rvm-modal')
		},

		async handleSaveNewsReader() {
			this.isSaving = true
			let colorLabel = this.settings.color == '#000' ? 'dark' : this.settings.color == '#FFFFFF' ? 'light' :  this.settings.color == 'Doodle' ? 'doodle' : 'gradient-nova'

			let formData = {
				content_name: this.contentName,
				url: this.webUrl,
				style: colorLabel,
				watermark: !this.settings.removeWatermark,
				speed: !this.settings.disableSpeed,
				voice_file: this.settings.audioSrc,
				url_html: this.urlHtml,
				embed_code: this.embed,
				text: this.resultText,
				avatar: this.avatar
			}

			let id = this.$route.params.id
			await saveToProjectNewsReader(formData, id)
			.then((response) => {
				if(response.status) {
					this.alertSuccess('Content successfully saved')
					this.removeDataFromStorage()
					this.$router.push({ name : 'My-Quiz', query : { folder_name: 'Readers' }})
				} else {
                	this.alertFail('Content failed to save')
				}
				this.$bvModal.hide('rvm-modal')
				this.isSaving = false
			})
			.catch((error) => {
				console.error(error)
				this.isSaving = false
				this.alertFail('Content failed to save')
			})
		},

		async getAllocation() {
			await subscriptionsStatus()
			.then((res) => {
				if(res.status) {
					this.allocation = res.data
				}
			})
		},

		async getDetail(id) {
			this.isLoading = true
			await getDetailNewsReader(id)
			.then((response) => {
				if(response.status) {
					let dataAvatar =  {
						character: {
							gender: 'Female',
							name: 'Sovia',
							avatarSrc: base_url_machine_learning + 'static/template/mp3/asset_sample_languages/Indonesia/Female/avatar/Sovia.png',
							isMultilingual:false,
							isUseModel: false,
							sampleSrc: base_url_machine_learning + 'static/template/mp3/asset_sample_languages/Indonesia/Female/normal/1677909003.mp3',
							thumbnailAvatarSrc: base_url_machine_learning + 'static/template/mp3/asset_sample_languages/Indonesia/Female/avatar/thumbnails/Sovia.png',
						},
						language: {
							code: 'ID',
							flagSrc: '/img/ic_flag_indonesia.bfd56f3d.svg',
							id: '6401ae940eb4d9111ec260d7',
							name: 'Indonesia',
						}
					}
					let style = response.data.style
					this.contentName = response.data.content_name
					this.webUrl = response.data.url
					this.urlHtml = response.data.url_html
					this.embed = response.data.embed_code
					this.resultText = response.data.text
					this.avatar = response.data.avatar || dataAvatar
					this.settings.removeWatermark = !response.data.watermark
					this.settings.disableSpeed = !response.data.speed
					this.settings.audioSrc = response.data.voice_file
					this.settings.color = style == 'dark' ? '#000' : style == 'light' ? '#FFFFFF' : style == 'doodle' ? 'Doodle' : 'Gradient Nova'

					this.generatedResultText = response.data.text
					this.generatedWebUrl = response.data.url
					this.generatedSettings = {...this.settings}
					this.generatedAvatar = response.data.avatar || dataAvatar

					sessionStorage.setItem("news-reader::get-from-api", true)
				}
				this.isLoading = false
			})
			.catch((error) => {
				console.error(error)
				this.isLoading = false
			})
		},

		getMonthDate(dateTime) {
			const months = [
				"Jan",
				"Feb",
				"Mar",
				"Apr",
				"May",
				"Jun",
				"Jul",
				"Aug",
				"Sep",
				"Oct",
				"Nov",
				"Dec",
			];

			const dateObject = new Date(dateTime);
			const tanggal = dateObject.getUTCDate();
			const monthIndex = dateObject.getUTCMonth();
			const month = months[monthIndex];

			return `${month} ${tanggal}`;
		},

		handleRemoveWm() {
			getAllowWatermark(this.$route.params.id)
			.then((response) => {
				if(response.status) {
					this.settings.removeWatermark = !this.settings.removeWatermark
				} else {
					checkBalanceSwcRemoveWM()
					.then((response) => {
						if(response.status) {
							this.statusSwcRemoveWm = response.data.swc_balance,
							this.balanceSwcForRemoveWm = response.data.price_remove_watermark,
							this.$bvModal.show("remove-wm-modal")
						}
					})
				}
			})
		},

		handleClickUseSwc() {
			checkStatusPin()
			.then((response) => {
				if(response.status) {
					this.$bvModal.show('input-pin-modal')
					// this.$router.push({ name : 'Swc-Payment', query : { content_id : this.$route.params.id, redirect : this.$route.fullPath } })
				} else {
					this.$bvModal.show('setup-pin-modal')
				}
				this.$bvModal.hide("remove-wm-modal")
			})
		},

		requestPinCode(){
			requestSetPin()
			.then((response) => {
				if(response.status) {
					this.$bvModal.show('verif-code-modal')
					this.$bvModal.hide('setup-pin-modal')
				} else {
					if(response.response?.response?.status == 429) {
						this.alertFail("Too many requests, please try again later.")
					}
				}
			})
		},

		openCreatePin() {
			this.$bvModal.hide('verif-code-modal')
			this.$bvModal.show('topup-create-pin-modal')
		},

		async getDataFromStorage() {
			this.isPopulatingAllDataOnStorage = true
			if(sessionStorage.getItem('news-reader::settings')) {
				this.settings = await JSON.parse(sessionStorage.getItem('news-reader::settings'))
			}

			if(sessionStorage.getItem('news-reader::generated-settings')) {
				this.generatedSettings = await JSON.parse(sessionStorage.getItem('news-reader::generated-settings'))
			}

			if(sessionStorage.getItem('news-reader::avatar')) {
				this.avatar = await JSON.parse(sessionStorage.getItem('news-reader::avatar'))
			}

			if(sessionStorage.getItem('news-reader::generated-avatar')) {
				this.generatedAvatar = await JSON.parse(sessionStorage.getItem('news-reader::generated-avatar'))
			}

			if(sessionStorage.getItem('news-reader::result-text')) {
				this.resultText = sessionStorage.getItem('news-reader::result-text')
			}

			if(sessionStorage.getItem('news-reader::generated-result-text')) {
				this.generatedResultText = sessionStorage.getItem('news-reader::generated-result-text')
			}

			if(sessionStorage.getItem('news-reader::web-url')) {
				this.webUrl = sessionStorage.getItem('news-reader::web-url')
			}

			if(sessionStorage.getItem('news-reader::generated-web-url')) {
				this.generatedWebUrl = sessionStorage.getItem('news-reader::generated-web-url')
			}

			if(sessionStorage.getItem('news-reader::embed')) {
				this.embed = sessionStorage.getItem('news-reader::embed')
			}

			this.isPopulatingAllDataOnStorage = false
		},

		removeDataFromStorage() {
			sessionStorage.removeItem('news-reader::settings')
			sessionStorage.removeItem('news-reader::generated-settings')
			sessionStorage.removeItem('news-reader::avatar')
			sessionStorage.removeItem('news-reader::generated-avatar')
			sessionStorage.removeItem('news-reader::result-text')
			sessionStorage.removeItem('news-reader::generated-result-text')
			sessionStorage.removeItem('news-reader::web-url')
			sessionStorage.removeItem('news-reader::generated-web-url')
			sessionStorage.removeItem('news-reader::embed')
			sessionStorage.removeItem('news-reader::get-from-api')
		},

		handleForgotPin(){
			this.$bvModal.hide('input-pin-modal')
            this.requestPinCode()
        },

		handleSuccessRemoveWM() {
			EventBus.$emit('triggerSidebarMethod');
			this.getAllocation()
			this.settings.canRemoveWm = true
			this.settings.removeWatermark = true
		},

		getSwcPrice(){
			this.load_swc_price = true
			checkPriceSwc()
			.then((response) => {
				if(response.status) {
					this.swc_price = response.data
					this.load_swc_price = false
				} else {
					this.load_swc_price = false
				}
			})
			.catch((err) => {
				console.log(err)
				this.load_swc_price = false
			})
		},

		getPackageSwc() {
			this.load_package_swc = true
			getPackageTopupSwc()
			.then((response) => {
				if(response.status){
					this.listPackageSwc = response.data
					this.load_package_swc = false
				} else {
					this.load_package_swc = false
				}
			})
			.catch((err) => {
				console.log(err)
				this.load_package_swc = false
			})
		},

		handleChoosedIndex(val) {
			this.custom_swc = null
			if(this.choosedIndex == val._id){
                this.totalPrice = 0
                this.choosedIndex = null;
            } else {
                this.choosedIndex = val._id
				this.totalPrice = val.price_idr
            }
		},

		swcCustomFocus() {
			if(this.choosedIndex) {
				this.choosedIndex = null;
				this.totalPrice = 0
			}
		},

		handleInputSwc(val) {
			const parsedValue = parseInt(val);
			if(!isNaN(parsedValue)) {
				this.custom_swc = parsedValue
			} else {
				this.custom_swc = null
			}
		},

		nextTopup() {
			this.$bvModal.hide('topup-swc-modal')
			if(this.choosedIndex) {
				this.createInvoice(this.choosedIndex)
			} else if(this.custom_swc) {
				this.postSwcRequest()
			}
		},

		createInvoice(id, community_id){
			this.load_topup = true;

			let formData = {
				package_id : id,
				community_id : community_id ? community_id : null
			}

			topupSwc(formData)
			.then((response) => {
				if(response.status == true){
					this.load_topup = false;
					this.$router.push({ name : 'Subscriptions', query : { id : response.data.subscription_id, redirect : this.$route.fullPath } })
				}
			})
			.catch((err) => {
				this.load_topup = false;
				console.log(err)
			})
		},

		handleCloseTopUp() {
			this.totalPrice = 0
            this.choosedIndex = null;
			this.custom_swc = null
			this.is_enough = false
			this.$bvModal.hide('topup-swc-modal')
		},

		postSwcRequest(){
			this.load_topup = true

			let formData = {
				swc_request : this.custom_swc
			}
			customSwcRequest(formData)
			.then((response) => {
				if(response.status){
					this.load_topup = false
					this.$router.push({ name : 'Subscriptions', query : { id : response.data.subscription_id, redirect : this.$route.fullPath } })
				} else {
					this.load_topup = false
				}
			})
			.catch((err) => {
				console.log(err)
				this.load_topup = false
			})
		},

		handleClickModalTopUpSwc() {
			this.$bvModal.hide('remove-wm-modal')
			this.$bvModal.show('topup-swc-modal')
		}

	}
}
</script>


<style scoped>
.news-reader {
	min-height: calc(100vh - 80px);
	color: #fff;
	text-align: left;
	gap: 8px;
}

.news-reader__inner {
	gap: 8px;
}

.news-reader__title {
	color: #FFF;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.news-reader__subtitle {
	color: #FFF;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.news-reader__main {
	display: contents;
}

.news-reader__middle {
	gap: 12px;
	min-height: 0;
}

.news-reader__left-content {
	width: 60%;
	gap: 12px;
}

.news-reader__right-content {
	width: 40%;
	gap: 12px;
}

.news-reader__subscription {
  gap: 6px;
}

.news-reader__subscription:hover .news-reader__tooltip {
  display: block;
}

.news-reader__tooltip {
  top: 14px;
  left: -235px !important;
  width: auto !important;
  background-color: #1F1F1F;
  color: #ffffff;
  font-size: 12px;
  padding: 10px 15px;
}

.news-reader__subscription-icon {
  height: 14px;
}

.news-reader__subscription-text {
  font-size: 14px;
  font-weight: 500;
}

.gv__tooltip--topup {
	color: #6D75F6;
}

.gv__tooltip--package {
	gap: 6px;
}

.gv__tooltip--package-wrapper {
	border-radius: 4px;
	background: #2D2D2D;
}

.gv__tooltip--package-wrapper-inner {
	gap: 4px;
}

.tooltip--package__text {
	color: #FFF;
	font-family: Avenir Next;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.tooltip--package__quota {
	color: #FFF;
	font-family: Avenir Next;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.gv__tooltip--package-quota {
	gap: 4px;
}

.tooltip--package__exp {
	color: #8C8C8C;
	font-family: Avenir Next;
	font-size: 8px;
	font-style: italic;
	font-weight: 500;
	line-height: normal;
}
</style>