<template>
	<b-modal
		id="expand-script-nr"
		size="xl"
		body-class="p-0"
		centered
		hide-header
		hide-footer
		busy
	>
		<header class="esnr__header d-flex align-items-center justify-content-between px-4 py-3">
			<button :disabled="isGenerate" type="button" class="esnr__avatar-button btn d-flex align-items-center" @click="$emit('click:change-avatar')">
				<img :src="avatar?.character.avatarSrc" :alt="avatar?.character.name" class="esnr__choose-voice--image">
				<div class="d-flex align-items-center ml-1">
					<p class="esnr__choose-voice--label m-0">{{ avatar?.character.name }} - {{ avatar.language.name }}</p>
					<img src="@/assets/images/icons/ic_chevron-bottom.svg" alt="" class="ml-4">
				</div>
			</button>
			<button
				type="button"
				class="esnr__expand-button esnr__expand-button--expanded btn d-inline-flex justify-content-center align-items-center p-0 text-white"
				title="Compress"
				@click="$bvModal.hide('expand-script-nr')"
			>
				<font-awesome-icon icon="fa-solid fa-compress" />
			</button>
		</header>
		<section class="esnr__main px-4 py-3">
			<textarea v-model="text" :readonly="isGenerate" class="esnr__textarea pr-2"></textarea>
		</section>
	</b-modal>
</template>

<script>
export default {
	props: {
		value: {
			type: String,
			default: null
		},
		avatar: {
			type: Object,
			default: () => {
				return {}
			}
		},
		isGenerate: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			text: this.value
		}
	},
	watch: {
		value: {
			handler(newValue) {
				this.text = newValue
			},
			deep: true,
			immediate: true
		},
		text: {
			handler(newValue) {
				this.$emit('input', newValue)
			},
			deep: true
		}
	}
}
</script>

<style scoped>
.esnr__header {
	gap: 8px;
  	border-bottom: 0.5px solid #2D2D2D;
}

.esnr__avatar-button {
	border-radius: 4px;
	border: 1px solid #2D2D2D;
	gap: 4px;
}

.esnr__choose-voice--image {
	width: 26px;
	height: 26px;
	border-radius: 50%;
}

.esnr__choose-voice--label {
	font-size: 14px;
	color: #fff;
}

.esnr__expand-button {
  width: 20px;
  height: 20px;
  background-color: #2d2d2d;
  border-radius: 4px;
  font-size: 12px;
  transition: background-color 0.25s;
}

.esnr__expand-button--expanded {
  width: 28px;
  height: 28px;
  font-size: 18px;
}

.esnr__expand-button:hover {
  background-color: #3D3D3D;
}

.esnr__textarea {
	border: none;
	background-color:transparent;
	color: white;
	width: 100%;
	height: 350px;
	resize: none;
	font-size: 1.15rem;
}

.esnr__textarea::-webkit-scrollbar {
  	width: 6px;
}

.esnr__textarea::-webkit-scrollbar-track {
  	border-radius: 6px;
	background: #2D2D2D;
}

.esnr__textarea::-webkit-scrollbar-thumb {
	background: #3D3D3D;
	border-radius: 6px;
	background-clip: padding-box;
}
</style>