<template>
	<div class="pae">
		<div class="pae__inner d-flex flex-column align-items-center px-4 py-3">
			<div class="w-100">
				<h5 class="pae__title text-center mb-1">{{ $t('preview-and-editing') }}</h5>
				<div class="d-flex align-items-center justify-content-between">
					<div></div>
					<p class="pae__small-text text-center m-0">{{ $t('preview-and-editing--p') }}</p>
					<button type="button" :disabled="isGenerate" class="pae__expand-button btn d-inline-flex justify-content-center align-items-center p-0 text-white float-right" @click="$emit('click:expand')">
						<font-awesome-icon icon="fa-solid fa-expand" />
					</button>
				</div>
			</div>
			<div class="pae__wrapper h-100 w-100 pl-4 py-4 pr-2">
				<textarea v-model="text" :readonly="isGenerate" class="pae__textarea pr-2"></textarea>
			</div>
			<div class="pae__choose-voice d-flex align-items-center">
				<p class="pae__choose-voice--text m-0">{{ $t('select-voices') }}</p>
				<button type="button" :disabled="isGenerate" class="pae__choose-voice--button btn d-flex align-items-end" @click="$emit('click:change-avatar')">
					<img :src="avatar?.character.avatarSrc" :alt="avatar?.character.name" class="pae__choose-voice--image">
					<div class="d-flex align-items-center">
						<p :title="avatar?.character.name" v-if="avatar?.character.isUseModel && !avatar?.character?.isMultilingual" class="pae__choose-voice--label m-0">{{ truncatedName }} (Clone) <span v-if="avatar.character.type == 'multilingual'">- {{ capitalizeText(avatar.character.type) }}</span> <span v-else>- {{ avatar.language.name }}</span></p>
						<p :title="avatar?.character.name" v-else class="pae__choose-voice--label m-0">{{ truncatedName }} - {{ avatar.language.name }}</p>
						<img src="@/assets/images/icons/ic_chevron-bottom.svg" alt="" class="ml-4">
					</div>
				</button>
			</div>
			<button :disabled="disableGenerate || isGenerate" type="button" class="pae__button-generate btn mt-2" @click="$emit('click:generate-audio')">{{ isGenerate ? 'Generating...' : 'Generate Audio'}}</button>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		value: {
			type: String,
			default: null
		},
		avatar: {
			type: Object,
			default: () => {
				return {}
			}
		},
		disableGenerate: {
			type: Boolean,
			default: true
		},
		isGenerate: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			text: this.value
		}
	},
	watch: {
		value: {
			handler(newValue) {
				this.text = newValue
			},
			deep: true,
			immediate: true
		},
		text: {
			handler(newValue) {
				this.$emit('input', newValue)
			},
			deep: true
		}
	},
	computed: {
		truncatedName() {
			const maxLength = 20;
			const ellipsis = '...';

			return this.avatar?.character.name.length > maxLength ? this.avatar?.character.name.substring(0, maxLength - ellipsis.length) + '...' : this.avatar?.character.name;
		},
	},
	methods: {
		capitalizeText(text) {
			return text.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
		},
	}
}
</script>

<style scoped>
.pae {
	padding: 2px;
	background-image: linear-gradient(to bottom right, #FFFFFF33, #FFFFFF11);
	border-radius: 8px;
	height: 100%
}

.pae__inner {
	background: linear-gradient(0deg, #121418 0%, #121418 100%), rgba(49, 48, 54, 0.30);
	border-radius: 8px;
	backdrop-filter: blur(2px);
	gap: 16px;
	height: 100%
}

.pae__title {
	font-size: 15px;
}

.pae__small-text {
	color: #FFF;
	font-family: Avenir Next;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.pae__expand-button {
	width: 24px;
	height: 24px;
	background-color: #2d2d2d;
	border-radius: 4px;
	font-size: 14px;
	transition: background-color 0.25s;
}

.pae__expand-button:hover {
	background-color: #3D3D3D;
}

.pae__wrapper {
	border-radius: 5px;
	background: #2C313C;
}

.pae__choose-voice {
	gap: 4px;
}

.pae__choose-voice--text {
	font-size: 14px;
}

.pae__choose-voice--button {
	border-radius: 4px;
	border: 1px solid #2D2D2D;
	gap: 4px;
}

.pae__choose-voice--image {
	width: 20px;
	height: 20px;
	border-radius: 50%;
}

.pae__choose-voice--label {
	font-size: 12px;
	color: #fff;
}

.pae__button-generate {
	background-color: #6D75F6;
	color: #fff;
	font-weight: 600;
	font-size: 16px;
	border-radius: 5px;
	padding: 7px 24px;
	width: 250px;
}

.pae__button-generate:not(:disabled):hover {
	background-color: #545EED;
}

.pae__button-generate:disabled {
	background-color: #2d2d2d;
	color: #8C8C8C;
}

.pae__textarea {
	border: none;
	background-color:transparent;
	color: white;
	width: 100%;
	height: 100%;
	resize: none;
}

.pae__textarea::-webkit-scrollbar {
  	width: 4px;
}

.pae__textarea::-webkit-scrollbar-track {
  	border-radius: 4px;
	background: #595E6A;
}

.pae__textarea::-webkit-scrollbar-thumb {
	background: #3E4350;
	border-radius: 4px;
	background-clip: padding-box;
}
</style>