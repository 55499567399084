<template>
    <b-modal id="remove-wm-modal" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="sm">
        <div class="mt-5 text-center text-white mb-3">
            <div class="mt-3">
                <h5 class="font-weight-bold mb-5" style="font-size:20px;">{{ $t('delete-watermark') }}</h5>
                <h5 class="font-weight-normal" style="font-size:16px; color: #8c8c8c;">{{ $t('delete-watermark--p') }}</h5>
				<div class="swc--information d-flex align-items-center justify-content-center mt-3">
                	<img src="@/assets/images/icons/ic_package_swc.png" alt="SWC" class="img-fluid quota-info__img">
                	<h5 class="font-weight-bold" style="font-size:30px;">{{ swc }}</h5>
                </div>
				<div v-if="!statusBalanceSwc" class="d-flex align-items-center justify-content-center mt-3 mb-4">
					<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
						<path d="M7.86567 8.5084H7.12687V5H7.86567V8.5084ZM8 9.53782C8 9.6592 7.95274 9.76657 7.85821 9.85994C7.76368 9.95331 7.64428 10 7.5 10C7.3607 10 7.24129 9.95565 7.14179 9.86695C7.04726 9.77358 7 9.6662 7 9.54482C7 9.41877 7.04726 9.31139 7.14179 9.22269C7.23632 9.12932 7.35572 9.08263 7.5 9.08263C7.64428 9.08263 7.76368 9.12698 7.85821 9.21569C7.95274 9.30439 8 9.41176 8 9.53782Z" fill="#F9130D"/>
						<circle cx="7.5" cy="7.5" r="6.5" stroke="#F9130D" stroke-width="0.5"/>
					</svg>
                	<h5 class="font-weight-normal" style="font-size:12px; color: #F9130D;">{{ $t('swc-not-enough') }}</h5>
				</div>
            </div>
			<hr style="background-color:#2d2d2d">
            <div class="vcm__action d-flex justify-content-center mt-3">
                <button class="btn ttvsrv__button ttvsrv__button--back" @click="$bvModal.hide('remove-wm-modal')">
					<p class="mx-2 mb-0 font-weight-bold text-white">{{ $t('cancel') }}</p>
				</button>
                <button class="btn ttvsrv__button ttvsrv__button--save" @click="handleUseSwc">
					<p class="mx-2 mb-0 font-weight-bold text-white">{{ labelSubmit }}</p>
				</button>
            </div>
        </div>
    </b-modal>
</template>

<script>
export default {
    props: {
        swc: {
            type: Number,
            default: 0
        },
		statusBalanceSwc: {
			type: Boolean,
			default: false
		}
    },
    data() {
        return {

        }
    },
	computed: {
		labelSubmit() {
			if(!this.statusBalanceSwc) {
				return "Top Up"
			} else {
				return "Use SWC"
			}
		}
	},
    methods: {
        handleUseSwc() {
            if (!this.statusBalanceSwc) {
                this.$emit('click:topup-swc');
            } else {
                this.$emit('click:use-swc');
            }
        }
    }
}
</script>


<style>

@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#remove-wm-modal .modal-content{
    background-color: #222222;
     font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


#remove-wm-modal .modal-content{
    border-radius: 10px !important;
    background-color: #1F1F1F;
}

.btn-purple {
    background-color: #6D75F6;
    border: 1px solid #6D75F6;
    color: white;
    box-shadow: none !important;
}
</style>

<style scoped>
.ttvsrv__button {
  border-radius: 6px;
  transition: background-color 0.25s;
}

.ttvsrv__button--save {
  font-weight: 600;
  background-color: #6D75F6;
}

.ttvsrv__button--save:not(:disabled):hover {
  background-color: #545EED;
}

.ttvsrv__button--back {
  font-weight: 500;
  background-color: #2D2D2D;
}

.ttvsrv__button--back:not(:disabled):hover {
  background-color: #3D3D3D;
}

.vcm__action {
	gap: 8px;
}

.quota-info__img {
	width: 38px;
}

.swc--information {
	gap: 8px;
}
</style>