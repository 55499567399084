<template>
	<div class="nr-settings">
		<div class="nr-settings__inner d-flex flex-column px-4 py-3">
			<h5 class="nr-settings__title text-center">Settings</h5>
			<p class="nr-settings__text m-0">Button Skin</p>
			<div class="nr-settings__list-style d-flex align-items-center">
				<!-- <div class="nr-settings__wrapper d-flex flex-column align-items-center justify-content-center">
					<tippy
						to="color-picker-toggle"
						trigger="click"
						boundary="window"
						placement="top"
						theme="cp-nr__wrapper"
						:animate-fill="false"
						:duration="250"
						:z-index="101"
						:max-width="500"
						visible
						interactive
					>
						<GVColorPicker :color="currentSettings.color" @change:color="(val) => currentSettings.color = val"/>
					</tippy>
					<button name="color-picker-toggle" type="button" class="nr-settings__style-button btn d-flex align-items-center justify-content-center" :style="{ backgroundColor: currentSettings.color != 'Doodle' && currentSettings.color != 'Gradient Nova' ? currentSettings.color : '#1F1F1F' }">
						<font-awesome-icon icon="fa-solid fa-eye-dropper" :class="{ 'nr-settings--icon-dark' : isHexLight && currentSettings.color != 'Doodle' }"/>
					</button>
					<p class="nr-settings__label m-0">Custom</p>
				</div> -->
				<div v-for="(bg, index) in listBackround" :key="index" class="nr-settings__wrapper d-flex flex-column align-items-center justify-content-center">
					<button type="button" class="nr-settings__style-button btn" @click="changeStyle(index)" :class="{ 'nr-settings__button-active' : currentSettings.color == bg.color || currentSettings.color == bg.label }" :style="{ backgroundColor: bg.color, backgroundImage: bg.label === 'Doodle' ? `url('${require('@/assets/images/icons/ic_background_doodle.svg')}')` : bg.label === 'Gradient Nova' ? `url('${require('@/assets/images/icons/ic_background_gradient_nova.svg')}')` : 'none', backgroundPosition: 'center' }"></button>
					<p class="nr-settings__label text-nowrap m-0">{{ bg.label }}</p>
				</div>
			</div>
			<div class="nr-settings__list-check d-flex flex-column py-2">
				<div class="nr-settings__check d-flex align-items-center" @click="$emit('click:remove-wm')">
					<vs-checkbox color="#6D75F6" id="removeWatermark" v-model="currentSettings.removeWatermark" disabled="true"></vs-checkbox>
					<label for="removeWatermark" class="nr-settings__text nr-settings__label-check m-0">{{ $t('setting-check1') }}</label>
				</div>
				<!-- <div class="nr-settings__check d-flex align-items-center">
					<vs-checkbox color="#6D75F6" id="useYourWatermark" v-model="currentSettings.useYourLogo"></vs-checkbox>
					<label for="useYourWatermark" class="nr-settings__text m-0">Use your logo as watermark</label>
				</div> -->
				<div class="nr-settings__check d-flex align-items-center">
					<vs-checkbox color="#6D75F6" id="disabledSpeed" v-model="currentSettings.disableSpeed"></vs-checkbox>
					<label for="disabledSpeed" class="nr-settings__text nr-settings__label-check m-0">{{ $t('setting-check3') }}</label>
				</div>
			</div>
			<div>
				<p class="nr-settings__text mb-1">{{ $t('preview') }}</p>
				<NRAudioPreview :audio-data="currentSettings"/>
			</div>
			<button :disabled="!currentSettings.audioSrc || isLoading || disableGetCode" class="nr-settings__button mx-auto btn" @click="$emit('click:get-embed-code')">{{ isLoading ? 'Loading...' : $t('get-code') }}</button>
		</div>
	</div>
</template>


<script>
import NRAudioPreview from '../audio-preview'
// import GVColorPicker from '@/components/generate-videos/color-picker'

export default {
	components: {
		NRAudioPreview,
		// GVColorPicker
	},

	props: {
		value: {
			type: Object,
			default: () => {
				return {}
			}
		},
		isLoading: {
			type: Boolean,
			default: false
		},
		disableGetCode: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			listBackround: [
				{
					color: '#000',
					label: 'Dark'
				},
				{
					color: '#FFFFFF',
					label: 'Light'
				},
				{
					color: null,
					label: 'Doodle'
				},
				{
					color: null,
					label: 'Gradient Nova'
				},
			],
			hexForDarkIcon: [
				'#FFFFFF',
				'#FFFFE0',
				'#FFFACD',
				'#ADD8E6',
				'#87CEEB',
				'#00FF00',
				'#90EE90',
				'#FFC0CB',
				'#FFA500',
				'#E6E6FA',
				'#00FFFF',
				'#FFF700',
				'#FFFDD0',
				'#C0C0C0',
				'#FFFF00',
				'DOODLE'
			],
			currentSettings: this.value
		}
	},

	watch: {
		value: {
			handler(newValue) {
				this.currentSettings = newValue
			},
			deep: true
		},
		currentSettings: {
			handler(newSettings) {
				this.$emit('input', newSettings)
			},
			deep: true
		}
	},

	computed: {
		isHexLight() {
			return this.hexForDarkIcon.includes(this.currentSettings.color.toUpperCase());
		},
	},

	methods: {
		changeStyle(index) {
			let newStyle = this.listBackround[index]

			if(newStyle.color) {
				this.currentSettings.color = newStyle.color
			} else {
				this.currentSettings.color = newStyle.label
			}
		},
		toggleRemoveWatermark() {
			if (this.currentSettings.canRemoveWm) {
				this.currentSettings.removeWatermark = !this.currentSettings.removeWatermark;
			} else {
				this.currentSettings.removeWatermark = false
			}
		}
	}
}
</script>

<style scoped>
.nr-settings {
	padding: 2px;
	background-image: linear-gradient(to bottom right, #FFFFFF33, #FFFFFF11);
	border-radius: 8px;
}

.nr-settings__inner {
	background: linear-gradient(0deg, #121418 0%, #121418 100%), rgba(49, 48, 54, 0.30);
	border-radius: 8px;
	backdrop-filter: blur(2px);
	gap: 10px;
}

.nr-settings__title {
	font-size: 15px;
}

.nr-settings__text {
	color: #FFF;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.nr-settings__label-check {
	font-size: 12px;
}

.nr-settings__list-style {
	gap: 24px;
}

.nr-settings__wrapper {
	gap: 6px;
	max-width: 34px;
}

.nr-settings__style-button {
	width: 34px;
	height: 34px;
	border-radius: 50%;
	background-color: #1f1f1f;
	color: #fff;
}

.nr-settings__button-active {
	border: 2px solid #6D75F6;
}

.nr-settings__label {
	font-size: 8px;
}

.nr-settings__list-check {
	gap: 6px;
}

.nr-settings__button  {
	background-color: #6D75F6;
	color: #fff;
	font-weight: 600;
	font-size: 16px;
	border-radius: 5px;
	padding: 7px 24px;
	width: 250px;
}

.nr-settings__button:not(:disabled):hover {
	background-color: #545EED;
}

.nr-settings__button:disabled {
	background-color: #2d2d2d;
	color: #8C8C8C;
}

.nr-settings--icon-dark {
  color: black;
}
</style>

<style>
.nr-settings__check .vs-checkbox--input:disabled, .nr-settings__check .vs-checkbox--input:disabled+.vs-checkbox .vs-checkbox--check {
	cursor: pointer !important;
    pointer-events: none;
}

.nr-settings__check .vs-checkbox--input:disabled+.vs-checkbox {
	opacity: 1;
	cursor: pointer !important;
    pointer-events: auto;
}

.nr-settings__check .vs-checkbox {
	border: 2px solid #2D2D2D !important;
	background-color: #2d2d2d;
}
.nr-settings__check .vs-checkbox--input:checked+.vs-checkbox {
	border: 2px solid rgb(109, 117, 246) !important;
}

.nr-settings__check .con-vs-checkbox {
	margin-left: 0px;
}
</style>

<style>
.cp-nr__wrapper-theme {
  background-color: #1f1f1f;
  position: relative;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.80);
}
</style>