<template>
	<div class="nr-embed">
		<div class="nr-embed__inner d-flex flex-column align-items-center px-4 py-2">
			<div class="d-flex align-items-center justify-content-between w-100">
				<p class="nr-embed__text m-0">Embeded URL</p>
				<button :disabled="!embed" class="nr-embed__copy-button nr-embed__text btn p-0" @click="$emit('click:copy-embed-code')">{{ $t('copy') }}</button>
			</div>
			<div class="nr-embed__wrapper h-100 w-100 p-2">
				<textarea readonly v-model="embed" class="nr-embed__textarea pr-2"></textarea>
			</div>
			<button :disabled="!embed || isSaving" class="nr-embed__save-button btn p-0 w-100" @click="$emit('click:save-to-project')">{{ isSaving ? 'Saving...' : $t('save-project') }}</button>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		embed: {
			type: String,
			default: null
		},
		isSaving: {
			type: Boolean,
			default: false,
		}
	}
}
</script>

<style scoped>
.nr-embed {
	padding: 2px;
	background-image: linear-gradient(to bottom right, #FFFFFF33, #FFFFFF11);
	border-radius: 8px;
	height: 100%;
}

.nr-embed__inner {
	background: linear-gradient(0deg, #121418 0%, #121418 100%), rgba(49, 48, 54, 0.30);
	border-radius: 8px;
	backdrop-filter: blur(2px);
	gap: 8px;
	height: 100%;
}

.nr-embed__wrapper {
	border-radius: 4px;
	background-color: #2C313C;
}

.nr-embed__text {
	font-size: 14px;
	color: #fff;
}

.nr-embed__copy-button, .nr-embed__save-button {
	color: #6D75F6;
	font-weight: 600;

	transition: color 0.25s;
}

.nr-embed__save-button{
	font-size: 16px;
	font-weight: 600;
}

.nr-embed__copy-button:not(:disabled):hover, .nr-embed__save-button:not(:disabled):hover {
	color: #545EED;
}

.nr-embed__copy-button:disabled, .nr-embed__save-button:disabled {
	color: #585859;
}

.nr-embed__textarea {
	border: none;
	background-color:transparent;
	color: white;
	width: 100%;
	height: 100%;
	resize: none;
}

.nr-embed__textarea::-webkit-scrollbar {
  	width: 4px;
}

.nr-embed__textarea::-webkit-scrollbar-track {
  	border-radius: 4px;
	background: #595E6A;
}

.nr-embed__textarea::-webkit-scrollbar-thumb {
	background: #3E4350;
	border-radius: 4px;
	background-clip: padding-box;
}
</style>