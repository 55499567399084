<template>
	<div class="nr-iwu">
		<div class="nr-iwu__inner d-flex flex-column justify-content-center align-items-center px-4 py-3">
			<h5 class="nr-iwu__title text-center">{{ $t('input-web-url') }}</h5>
			<input type="text" class="nr-iwu__input" :readonly="disableInput" v-model="url" placeholder="https://www.myarticle.com/publishes_01" autocomplete="false">
			<button :disabled="!url || disableControl" class="nr-iwu__button btn mt-2" @click="$emit('click:get-article')">{{ isScrapping ? $t('scrapping-the-article') : $t('get-the-article') }}</button>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		value: {
			type: String,
			default: null
		},
		isScrapping: {
			type: Boolean,
			default: false
		},
		disableControl: {
			type: Boolean,
			default: false
		},
		disableInput: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			url: this.value
		}
	},

	watch: {
		url: {
			handler(newUrl) {
				this.$emit('input', newUrl)
			},
			deep: true
		},
		value: {
			handler(newValue) {
				this.url = newValue
			},
			deep: true
		}
	}
}
</script>

<style scoped>
.nr-iwu {
	padding: 2px;
	background-image: linear-gradient(to bottom right, #FFFFFF33, #FFFFFF11);
	border-radius: 8px;
}

.nr-iwu__inner {
	background: linear-gradient(0deg, #121418 0%, #121418 100%), rgba(49, 48, 54, 0.30);
	border-radius: 8px;
	backdrop-filter: blur(2px);
	gap: 16px;
}

.nr-iwu__title {
	font-size: 15px;
}

.nr-iwu__input {
	padding: 12px 10px;
	color: #fff;
	text-align: center;
	width: 100%;
	font-size: 14px;
	border-radius: 4px;
	border: 1px solid #2D2D2D;
	background-color: transparent;
}

.nr-iwu__input::placeholder {
	color: #585859;
}

.nr-iwu__button {
	background-color: #6D75F6;
	color: #fff;
	font-weight: 600;
	font-size: 16px;
	border-radius: 5px;
	padding: 7px 24px;
	width: 250px;

	transition: background-color 0.25s;
}

.nr-iwu__button:not(:disabled):hover {
	background-color: #545EED;
}

.nr-iwu__button:disabled {
	background-color: #2d2d2d;
	color: #8C8C8C;
}
</style>