<template>
	<div ref="cardAudioPlayer" class="nr-ap px-3 py-1 position-relative" :style="{ backgroundColor: audioData.color == 'Gradient Nova' ? '#1f1f1f' : audioData.color, backgroundImage: audioData.color === 'Doodle' ? `url('${require('@/assets/images/icons/bg_doodle.svg')}')` : audioData.color === 'Gradient Nova' ? `url('${require('@/assets/images/icons/bg_gradient_nova-2.svg')}')` : 'none' }">
		<div class="container-fluid p-0 my-3">
			<div class="d-flex align-items-center justify-content-between">
				<div class="mr-2">
					<button :disabled="isLoading || !audioData.audioSrc" class="nr-ap__play-button btn d-flex align-items-center justify-content-center" :class="{ 'nr-ap__play-button--dark' : isHexLight }" type="button" :title="`${isPlaying ? 'Pause' : 'Play'}`" @click="toggleAudioPlayer">
						<font-awesome-icon :icon="`fa-solid ${isPlaying ? 'fa-pause' : 'fa-play'}`"/>
					</button>
				</div>
				<div class="w-100">
					<div class="d-flex align-items-center">
						<div class="progress nr-ap__progress w-100 mr-1">
							<div class="progress-bar" role="progressbar" :style="`width: ${(currentTime / duration) * 100}%`" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
						</div>
						<span class="nr-ap__time-label" :class="{ 'nr-ap__powered-text-dark' : isHexLight }">{{ calculateTime(currentTime) }}</span>
					</div>
					<div class="d-flex align-items-center justify-content-between mt-1">
						<div class="rewind--action d-flex align-items-center">
							<button :disabled="!audioData.audioSrc" class="rewind--button d-flex align-items-center justify-content-center btn" @click="skipBackward">
								<img v-if="isHexLight" src="@/assets/images/icons/ic_backward_10_dark.svg" alt="" class="fbward__icon">
								<img v-else src="@/assets/images/icons/ic_backward_10.svg" alt="" class="fbward__icon">
							</button>
							<button :disabled="!audioData.audioSrc" class="rewind--button d-flex align-items-center justify-content-center btn" @click="skipForward">
								<img v-if="isHexLight" src="@/assets/images/icons/ic_forward_10_dark.svg" alt="" class="fbward__icon">
								<img v-else src="@/assets/images/icons/ic_forward_10.svg" alt="" class="fbward__icon">
							</button>
						</div>
						<button v-if="!audioData.disableSpeed" :disabled="!audioData.audioSrc" class="speed--button btn" :class="{ 'speed--button-light' : audioData.color == 'Gradient Nova' }" @click="setSpeed">
							{{ labelPlaybackRate }}
						</button>
					</div>
				</div>
			</div>
		</div>
		<div v-if="!audioData.removeWatermark" class="nr-ap__powered-by d-flex align-items-center justify-content-center">
			<span class="nr-ap__powered-text" :class="{ 'nr-ap__powered-text-dark' : isHexLight }">Powered by</span>
			<img v-if="isHexLight" src="@/assets/images/icons/ic_nr_soca_dark.svg" alt="" width="28px">
			<img v-else src="@/assets/images/icons/ic_nr_soca_light.svg" alt="" width="28px">
		</div>
    	<audio ref="audioPlayer" :src="audioData.audioSrc" preload="metadata" />
	</div>
</template>

<script>
import getBlobDuration from 'get-blob-duration';

export default {
	data() {
		return {
			hexForDarkIcon: [
				'#FFFFFF',
				'#FFFFE0',
				'#FFFACD',
				'#ADD8E6',
				'#87CEEB',
				'#00FF00',
				'#90EE90',
				'#FFC0CB',
				'#FFA500',
				'#E6E6FA',
				'#00FFFF',
				'#FFF700',
				'#FFFDD0',
				'#C0C0C0',
				'#FFFF00',
				'DOODLE'
			],
			duration: 0,
			currentTime: 0,
			increaseSpeed: 0,
			sliderMax: 100,
			isLoading: false,
			isPlaying: false,
			src: "https://stg-ml.soca.ai/static/audio/633529b6089c3acaf046bf22/64f7d1b12adc718ecc056175/1693962677.mp3"
		}
	},

	props: {
		audioData: {
			type: Object,
			default: () => {
				return {}
			}
		}
	},

	computed: {
		isHexLight() {
			return this.hexForDarkIcon.includes(this.audioData.color.toUpperCase());
		},

		labelPlaybackRate() {
			let playbackLabel = ['1x', '1,5x', '2x', '0,5x']

			return playbackLabel[this.increaseSpeed]
		}
	},

	methods: {
		async toggleAudioPlayer() {
			if (!this.isLoading) {
				this.isLoading = true;

				try {
					if (this.isPlaying) {
						await this.$refs.audioPlayer.pause();
						this.isPlaying = false;
					} else {
						await this.$refs.audioPlayer.play();
						this.isPlaying = true;
						// this.$emit('play', this.pauseAudioPlayer, this._uid);
					}
				} catch (error) {
					console.log(error);
				}
				this.isLoading = false;
			}
		},

		async pauseAudioPlayer() {
			this.isLoading = true;

			try {
				if (this.isPlaying) {
					await this.$refs.audioPlayer.pause();
					this.isPlaying = false;
				}
			} catch (error) {
				console.log(error);
			}

			this.isLoading = false;
		},

		timeupdateListener() {
			const currentSeconds = parseFloat(this.$refs.audioPlayer.currentTime.toFixed(1));
			if (!this.doSliding) {
				this.currentTime = currentSeconds
			}

			if (currentSeconds >= parseFloat(this.$refs.audioPlayer.duration.toFixed(1)) && this.$refs.audioPlayer.paused) {
				this.isPlaying = false;
			}
		},

		async loadedmetadataListener() {
			if (this.audioData.audioSrc.search('blob:') >= 0) {
				this.duration = await getBlobDuration(this.audioData.audioSrc);
			} else {
				this.duration = this.$refs.audioPlayer.duration;
			}
		},

		skipBackward() {
			const audio = this.$refs.audioPlayer;
			audio.currentTime -= 10;
		},

		skipForward() {
			const audio = this.$refs.audioPlayer;
			audio.currentTime += 10;
			// const currentTime = audio.currentTime;
			// const duration = audio.duration;
			// const newTime = currentTime + 10;

			// if (newTime < duration) {
			// 	audio.currentTime = newTime;
			// } else {
			// 	audio.currentTime = duration;
			// }
		},

		setSpeed() {
			if(this.increaseSpeed < 3) {
				this.increaseSpeed++;
			} else {
				this.increaseSpeed = 0
			}
			this.updateSpeed()
		},

		updateSpeed() {
			let playbackRate = this.increaseSpeed == 0 ? 1 : this.increaseSpeed == 1 ? 1.5 : this.increaseSpeed == 2 ? 2 : 0.5
			const audio = this.$refs.audioPlayer;
			audio.playbackRate = playbackRate;
		},

		calculateTime(secs) {
			const remainingTime = this.duration - secs;
			const minutes = Math.max(Math.floor(remainingTime / 60), 0);
			const seconds = Math.max(Math.floor(remainingTime % 60), 0);
			const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
			const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
			return `${returnedMinutes}:${returnedSeconds}`;
		},

		pauseAudioWhenClickOutside(e) {
			if (!this.$refs.cardAudioPlayer.contains(e.target) && this.isPlaying) {
				this.pauseAudioPlayer()
			}
		},
	},
	mounted() {
		this.$refs.audioPlayer.addEventListener('loadedmetadata', this.loadedmetadataListener);
		this.$refs.audioPlayer.addEventListener('timeupdate', this.timeupdateListener);
      	document.addEventListener('click', this.pauseAudioWhenClickOutside);
	},
	beforeDestroy() {
		this.$refs.audioPlayer.removeEventListener('loadedmetadata', this.loadedmetadataListener);
		this.$refs.audioPlayer.removeEventListener('timeupdate', this.timeupdateListener);
     	 document.removeEventListener('click', this.pauseAudioWhenClickOutside);
	},
}
</script>

<style scoped>
.nr-ap {
	border-radius: 7px;
	background-color: #1f1f1f;
	/* background-image: url('~@/assets/images/icons/bg_doodle.svg'); */
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.nr-ap__play-button {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: #fff;
	color: black;
}

.nr-ap__play-button--dark {
	background-color: black;
	color: #fff;
}

.nr-ap__time-label {
	font-size: 8px;
	color: #fff;
	line-height: initial;
}

.rewind--action {
	gap: 6px;
}

.rewind--button {
	height: 24px;
	width: 24px;
	border-radius: 50%;
	padding: 8px;

	transition: background-color 0.25s;
}

.rewind--button:not(:disabled):hover {
	background-color: #585859;
}

.speed--button {
	border-radius: 10px;
	background-color: #8C8C8C;
	font-size: 10px;
	color: #fff;
	padding: 1px 7px;
	line-height: normal;
}

.speed--button-light {
	background-color: #fff;
	color: black;
}

.nr-ap__powered-by {
	gap: 2px;
	position: absolute;
	bottom: 5px;
	left: 0px;
	right: 0px;
}

.nr-ap__powered-text {
	font-size: 8px;
	line-height: normal;
}

.nr-ap__powered-text-dark {
	color: black;
}

.nr-ap__progress {
	height: 4px;
	background-color: #8C8C8C;
}

.nr-ap__progress .progress-bar {
	background-color: #6D75F6;
}

.fbward__icon {
	height: 15px;
}
</style>